import React from "react";
import Head from "../../components/head.js";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import { OutboundLink } from "gatsby-plugin-google-analytics";

import Layout from "../../components/layout";

const Nei = () => {
  return (
    <Layout>
      <Head title="Nei Caetano da Silva" />
      <h3>Nei Caetano da Silva</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        San Francisco, CA
      </h4>
      <p>
        <OutboundLink href="https://ello.co/marginalink">Ello</OutboundLink>
      </p>
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/14671938/ello-optimized-9c327e45.jpg"
        alt="Nei Caetano da Silva art"
      />
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/14671939/ello-optimized-4e3eed4a.jpg"
        alt="Nei Caetano da Silva art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/14528454/ello-optimized-aefc1e11.jpg"
        alt="Nei Caetano da Silva art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/14575168/ello-optimized-31ee003b.jpg"
        alt="Nei Caetano da Silva art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/15372641/ello-optimized-6a676b47.jpg"
        alt="Nei Caetano da Silva art"
      />
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/15372640/ello-optimized-2f408219.jpg"
        alt="Nei Caetano da Silva art"
      />
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/14581148/ello-optimized-4730fd5b.jpg"
        alt="Nei Caetano da Silva art"
      />
    </Layout>
  );
};

export default Nei;
